import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Avatar, Container } from "@chakra-ui/react";
import profDrUlrichAnders from "../img/jpg/0343/1x1/prof-dr-ulrich-anders_1x1.jpg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Flex = makeShortcode("Flex");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About | THE PROJECT STATUS" urlPage="/about" mdxType="SEO" />
    <Container maxW="800px" minH="640px" px={4} mdxType="Container">
      <h1 {...{
        "id": "about",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#about",
          "aria-label": "about permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`About`}</h1>
      <p>{`Welcome to THE PROJECT STATUS. THE PROJECT STATUS app is based on:`}</p>
      <ul>
        <li parentName="ul">{`many years of project experience in both software and organizational projects,`}</li>
        <li parentName="ul">{`hundreds of Excel sheets that have been filled or analysed in order to keep
track with project statuses,`}</li>
        <li parentName="ul">{`the analysis of many project reports across the industry,`}</li>
        <li parentName="ul">{`current academic research and lectures,`}</li>
        <li parentName="ul">{`a significant conceptual understanding of both agile and waterfall project
management methodologies,`}</li>
        <li parentName="ul">{`the psychology of the red traffic light,`}</li>
        <li parentName="ul">{`the review of what project status reports are used in the industry and in the
literature`}</li>
        <li parentName="ul">{`advanced tree algorithms for managing intertwined vertical and horizontal
trees.`}</li>
      </ul>
      <p>{`This app has been programmed by Prof. Dr. Ulrich Anders, Professor of Strategic
Management at the `}<a parentName="p" {...{
          "href": "https://cbs.de",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`CBS International Business School`}</a>{` in
Cologne. Algorithmic support has been provided by Jasper Anders, B. Sc. in
Business Engineering from `}<a parentName="p" {...{
          "href": "https://www.kit.edu/index.php",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`KIT`}</a>{`, Master Student
of Business & Computer Science at KIT and previously part-time developer at
`}<a parentName="p" {...{
          "href": "https://ambient.digital/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Ambient Innovation`}</a>{`.`}</p>
      <p>{`Feel invited to use THE PROJECT STATUS app. I am happy to receive your feedback.
You can send it to: prof.anders@online.de.`}</p>
      <h2 {...{
        "id": "why",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#why",
          "aria-label": "why permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Why`}</h2>
      <p>{`Many projects fail in the sense of the definition that they are delivered late,
over budget or with a lesser scope and quality then planned or promised.`}</p>
      <p>{`Projects are typically complex, technically or organizationally, and involve a
lot of stakeholders that may politically or with their power influence the
project.`}</p>
      <p>{`Managing this complexity needs instruments. The amount of textbooks and articles
on project management is vast. In contrast, the amount of textbooks and articles
on project control is tiny. At best this discipline is covered in maximum one
chapter of the project management books. Project control seems to be the
forgotten discipline.`}</p>
      <p>{`As a further result no gold standard has developed for good project status
reporting that helps the project manager and the persons involved in the project
to keep the overview of the project. Even across the most prominent project
software tools, the approach to project control is widely different. Some tools
do not offer regular project status reporting at all, others leave the user
alone to construct some project reporting and again others offer status reports
that are difficult to understand or read.`}</p>
      <p>{`Some remedy for the lack of transparency has been achieved through agile Kanban
boards and daily stand-ups but the transparency achieved through this approach
basically only helps the people who stand in front of the board. If no further
reporting is constructed Kanban boards do not bring the necessary transparency
to managers, customers and stakeholders surrounding the core team.`}</p>
      <p>{`In order to understand what the criteria for good project status reports are, I
have developed the 10 Commandments of Project Control. This app is based on
these 10 Commandments. With an app I hope to contribute to the research in this
field beyond an academic research paper. In order to stand its test in reality,
I have tried to make THE PROJECT STATUS app as convenient to use as possible.`}</p>
      <p>{`Academics, researchers and practitioners are invited to use THE PROJECT STATUS,
try it out and give feedback. Thank you.`}</p>
      <h2 {...{
        "id": "for-whom",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#for-whom",
          "aria-label": "for whom permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`For Whom`}</h2>
      <p>{`Currently THE PROJECT STATUS app is on VERSION 0.17.6.alpha for testing and
final development. Once the development is completed and we reach VERSION 1.0.0
the app will be offered for free:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`to Universities, academics, lectures and students: I feel that project control
is an underrepresented discipline and hope to contribute with this app to the
education in this field. I am also convinced that better project status
reporting will significantly improve the success rates of projects because
project status reporting adds transparency to a project and enforces a
delivery and quality focus. If you are interested in cooperation on further
research, please get in contact.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`to companies: Still, many companies use Spreadsheet solutions at large.
Spreadsheet solutions suffer from many obvious disadvantages. If you consider
this app useful for your company, please get in contact for a potential
cooperation or further development.`}</p>
        </li>
      </ul>
      <p>{`Please contact me under: prof.anders@online.de.`}</p>
      <h2 {...{
        "id": "thanks",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#thanks",
          "aria-label": "thanks permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Thanks`}</h2>
      <p>{`A number of people provided help, suggestions or bug reports. To them I would
like to extend my thanks.`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`To Jasper Anders who wrote the quite demanding and difficult intertwined tree
algorithms of the app. He also added many of the functions that combine the
front-end with the intelligence and underlying logic of the app. During the
MVP-phase, he has been an excellent discussion partner for the overall design
concept and the behavior of THE PROJECT STATUS.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`To my students in the early MVP-stage of the app: Matthias Hegger, Emir Piraj,
Emil Sømme, Justin Kumar.`}</p>
        </li>
      </ul>
      <h2 {...{
        "id": "the-author",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#the-author",
          "aria-label": "the author permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`The Author`}</h2>
      <Flex align="center" mb={4} mdxType="Flex">
  <Flex flex="0 0 auto" pr={8} direction="column" align="center" w={["30%"]} mdxType="Flex">
    <Avatar src={profDrUlrichAnders} size="full" mb={2} mdxType="Avatar" />
    <Text mdxType="Text">Prof. Dr. Ulrich Anders</Text>
  </Flex>
  <Text mdxType="Text">
    After years in various management and executive positions, after significant
    experiences in the area of project management, and after some time as a
    management advisor I am now a Professor of Strategic Management at CBS
    International Business School in Cologne. In addition, I also work as the
    Managing Director of the Universities' founders network cologne
    (<Link href="https://hgnc.de" mdxType="Link">hochschulgründernetz cologne e.V.</Link>),
    which has the aim to support founders
    to startup their own businesses. In this context I have also developed the
    Visual Business Planner app:{" "}
    <Link href="https://business-planner.online" isExternal mdxType="Link">
      https://business-planner.online
    </Link>
    . If you want to find out more about me and my research, please visit:{" "}
    <Link href="https://ulrich-anders.eu" isExternal whiteSpace="nowrap" mdxType="Link">
      https://ulrich-anders.eu
    </Link>.
  </Text>
      </Flex>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      